@import '~src/css/quasar.variables.scss', 'quasar/src/css/variables.sass';


.fast-link {
    &:hover {
        font-weight: 600;
        color: $positive !important;
    }

    border-radius: 16px; 
    background: $grey-20;
}

.marketing {
    background: #E1F2E9;
    border-radius: 26px;
}
